import React from 'react'

let localizacion = "Localizacion";
let zona = "Urbana";
let area = "Area";
let componente = "Pánales solares, reciclaje de agua, huertas urbanas, economía circular, ventilación pasiva";


export const PrimerComponente = () => {
  return (
<section className="hero is-success is-halfheight">
<div className="hero-body">
  <div className="">
    <p className="title">
      Hola, soy Felipe Velásquez Palacio
      <div>Soy Arquitecto y quiero hacer tu vida más fácil.
        <p> Quiero ayudar a construir tu casa de los sueños.</p>
        <p> Mi casa tiene {localizacion}.</p>
        <p> Mi casa tiene {zona}.</p>
        <p> Mi casa tiene {area}.</p>
        <p> Mi casa tiene {componente}.</p>
    </div>
    </p>
    <p className="subtitle">
      Todos estos componentes te ayudarán a vivir mejor. 
    </p>
  </div>
</div>
</section>
  )
}

