import React from 'react';

const Header = () => {
    return (
        <div className="navbar is-white"> 
            <div className="navbar-bran">
                <a className='navbar-item'>
                    <img src="logo2.png"></img>
                </a>
                Arquitecto Felipe Velásquez Palacio
            </div>
            <hr class="navbar-divider"></hr>
        </div> 
    );
}

export default Header;