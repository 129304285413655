

import { PrimerComponente } from './components/PrimerComponente';
import { SegundoComponente } from './components/SegundoComponente';
import Header from './components/Header';
import Footer from './components/Footer';
import Slider from 'react-slick';
import './index.css';
import 'bulma/css/bulma.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { dataDigitalBestSeller } from './components/data';

function App() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 8,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 8,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 8,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="App">
      <header className="App-header">
        <Header/>
        <PrimerComponente/>
       
        <SegundoComponente/>
        <Slider {...settings}>
        {dataDigitalBestSeller.map((item) =>
         <div className="Card">
          <img src={item.linkImg} alt={item.title}/>
          <h1>{item.title}</h1>
         <div className="Card-top">
         <div className="Card-botton">
          <h3>{item.price}</h3>
          <p>{item.category}</p>
 
         </div>
         </div>
         </div>
        )}
        </Slider>
        <Slider {...settings}>
        {dataDigitalBestSeller.map((item) =>
         <div className="Card">
          <img src={item.linkImg} alt={item.title}/>
          <h1>{item.title}</h1>
         <div className="Card-top">
         <div className="Card-botton">
          <h3>{item.price}</h3>
          <p>{item.category}</p>
 
         </div>
         </div>
         </div>
        )}
        </Slider>
        <PrimerComponente/>
        

      </header>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
