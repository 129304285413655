import React from 'react';

const Footer = () => {
    return (
        <footer class="footer">
  <div class="content has-text-centered">
    <p>
      <strong>Arquitecto Felipe Velásquez Palacio</strong> 
    </p>
  </div>
</footer>
    );
}

export default Footer;